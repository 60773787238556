import {Component, Input} from "@angular/core";
import {LabelComponentViewModel} from "./LabelComponentViewModel";

@Component({
  selector: 'label-component',
  templateUrl: './label-component.component.html',
  host: {
    "[class]": "'componentBody LabelComponent ' + viewModel.customCssClass + ' ' + viewModel.cssClasses",
    "[style]": "viewModel.css"
  }
})
export class LabelComponentComponent {
  @Input({required:true}) viewModel!: LabelComponentViewModel;

}
