import {environment} from "@environments";
import {AggregateId} from "./cqrs/AggregateId";
import {LocalDate, LocalDateTime} from "./data-types/Time";
import {Language} from "./data-types/Language";
import {None, Option} from "./data-types/Option";
import {global} from "./global";

export interface Config {
  restCallsToOrigin: boolean;
  restServicePort: number;
  restServiceProtocol: string;
  singleOrganization: boolean;
  applicationVersion: string;
  buildTimestamp: string;
  internalAuthentication: boolean;
  loginWithUsername: boolean;
  licenseOwner: string;
  organizationId: Option<AggregateId>;
  organizationName: Option<string>;
  organizationLogoUrl: Option<string>;
  organizationLanguage: Option<Language>;
  accentColor: Option<string>;
  mainApplicationUrl: string;
  serverTime: LocalDateTime;
  serverClientTimeMillsDiff: number;
  googleMapsApiKey: string;
  maxFileSize: number;
  iconColor: string;
  instanceName: string;
  officeOnlineEnabled: boolean;
  theme: string;
  paymentEnabled: boolean;
  paymentWarningDuration: number;
  customProcessCard: boolean;
  webdavPort: number;
  webdavProtocol: string;
  filesSharingEnabled: boolean;
  signaturesVerificationEnabled: boolean;
  diagramsNetEnabled: boolean;
  diagramsNetUrl: string;
  accessibilityEnabled: boolean;
  kerberosSsoEnabled: boolean;
  licenseExpirationInfo: {expired?: boolean, expiresOn?: LocalDate};
  usersLimitInfo: {exceededBy?: number};
  entraIdSSO: Array<{name: string, identifier: string}>;
}

const config: Config = {
  restCallsToOrigin: environment.restCallsToOrigin,
  restServiceProtocol: environment.restServiceProtocol,
  restServicePort: environment.restServicePort,
  singleOrganization: false,
  internalAuthentication: false,
  loginWithUsername: false,
  organizationId: None(),
  organizationLogoUrl: None(),
  organizationName: None(),
  organizationLanguage: None(),
  accentColor: None(),
  mainApplicationUrl: "",
  applicationVersion: "0.0",
  buildTimestamp: "unknown",
  licenseOwner: "unknown",
  serverTime: undefined!,
  serverClientTimeMillsDiff: 0,
  paymentEnabled: false,
  paymentWarningDuration: 5,
  googleMapsApiKey: "",
  maxFileSize: 0,
  iconColor: "",
  instanceName: "",
  officeOnlineEnabled: false,
  theme: "main",
  customProcessCard: false,
  webdavPort: 0,
  webdavProtocol: "",
  filesSharingEnabled: false,
  signaturesVerificationEnabled: false,
  diagramsNetEnabled: false,
  diagramsNetUrl: "",
  accessibilityEnabled: false,
  kerberosSsoEnabled: false,
  licenseExpirationInfo: {},
  usersLimitInfo:{},
  entraIdSSO: []
};

global.config = config;

export const currentUrl = location.protocol+'//'+location.hostname+(location.port ? ':'+location.port: '')+'/';

export const domainUrl = location.hostname+(location.port ? ':'+location.port: '')+'/';

let restUrlCache: string | null = null;

export function restUrl(urlPath: string) {
  if(restUrlCache == null) {
    if(config.restCallsToOrigin) {
      restUrlCache = '/';
    } else if(config.restServicePort !== 80 && config.restServicePort !== 443) {
      restUrlCache = config.restServiceProtocol+'://'+location.hostname + ':' + config.restServicePort + '/';
    } else {
      restUrlCache = config.restServiceProtocol+'://'+location.hostname + '/';
    }
  }

  return restUrlCache + urlPath;
}

export function webdavUrl(urlPath: string): string {
  return config.webdavProtocol+'://'+location.hostname + ':' + config.webdavPort + '/' + urlPath
}

let restUrlWithoutOrgCache: string | null = null;

export function restUrlWithoutOrganization(urlPath: string) {
  if(restUrlWithoutOrgCache == null) {
    let hostname = location.hostname.substring(location.hostname.indexOf('.') + 1, location.hostname.length);
    if(config.restCallsToOrigin) {
      restUrlWithoutOrgCache = location.protocol + "//"+hostname+"/";
    } else if(config.restServicePort !== 80 && config.restServicePort !== 443) {
      restUrlWithoutOrgCache = config.restServiceProtocol+'://'+hostname + ':' + config.restServicePort + '/';
    } else {
      restUrlWithoutOrgCache = config.restServiceProtocol+'://'+hostname + '/';
    }
  }

  return restUrlWithoutOrgCache + urlPath;
}

