import {Injectable, NgModule} from "@angular/core";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  BaseRouteReuseStrategy,
  NavigationEnd,
  ResolveData,
  Route,
  Router,
  RouteReuseStrategy,
  RouterModule,
  ROUTES,
  Routes,
} from "@angular/router";
import {LoginPageComponent} from "./pages/login/login-page.component";
import {PageNotFoundPageComponent} from "./pages/page-not-found/page-not-found-page.component";
import {LoggedInGuard} from "./guards/logged-in.guard";
import {PageLayoutResolver, StyleSet, StylesResolver, Translation, TranslationsResolver} from "@shared";
import {MainMenuResolver} from "./components/main-menu/main-menu.resolver";
import {PageLayout, PageLayoutService} from "./resolvers/page-layout.service";
import {MainMenuPage} from "./components/main-menu/main-menu.service";
import {PageLayoutComponent} from "./components/page-layout/page-layout.component";
import {RegisterOrganizationPageComponent} from "./pages/register-organization/register-organization-page.component";
import {EmailConfirmationPageComponent} from "./pages/email-confirmation/email-confirmation-page.component";
import {PasswordRecoveryPageComponent} from "./pages/password-recovery/password-recovery-page.component";
import {PasswordResetPageComponent} from "./pages/password-reset/password-reset-page.component";
import {StylesPageComponent} from "./pages/styles/styles-page.component";
import {FontsTestPageComponent} from "./pages/styles/fonts-test-page.component";
import {global, isStandalonePage, valueOrDefault} from "@utils";
import {ApplicationResolverService} from "@shared-model";
import {AboutPageComponent} from "./pages/about/about-page.component";
import {NonLoggedInGuard} from "./guards/non-logged-in.guard";
import {ScreenPortletComponent} from "./modules/screen.module/screen-portlet.component";
import {AuthenticateUserPageComponent} from "./pages/authenticate-user/authenticate-user-page.component";
import {StartTaskPortletComponent} from "./modules/task-form.module/start-task-portlet.component";
import {ImpersonatePageComponent} from "./pages/impersonate/impersonate-page.component";
import {LoginEntraIdPageComponent} from "./pages/login/login-entra-id-page.component";

const defaultStyles = [StyleSet.sharedModel];
const defaultTranslations = [Translation.tasks, Translation.notifications, Translation.user];

function standalone(route: Route, translations: Array<Translation> = [], styles: Array<StyleSet>): Route {
  route.resolve = {translation: TranslationsResolver, styles: StylesResolver};
  if (route.data == null) {
    route.data = {};
  }
  route.data["styles"] = styles;
  route.data["translations"] = translations;
  return route;
}

function any(route: Route, pageLayout: PageLayout, translations: Array<Translation>, styles: Array<StyleSet>, preloaderControl: boolean = false): Route {
  route.resolve = {
    translation: TranslationsResolver,
    pageLayout: PageLayoutResolver,
    styles: StylesResolver
  };

  if (route.data == null) {
    route.data = {};
  }
  route.data["styles"] = defaultStyles.concat(styles);
  route.data["translations"] = defaultTranslations.concat(translations); // 'translations' will be read by TranslationsResolver to know which translation files to load
  route.data["pageLayout"] = pageLayout;
  route.data["preloaderControl"] = preloaderControl;
  return route;
}

function authorized(route: Route, mainMenuPage: MainMenuPage, pageLayout: PageLayout, translations: Array<Translation> = [], styles: Array<StyleSet>, preloaderControl: boolean = false, customResolvers: ResolveData = {}): Route {
  return anyAuthorized(true, route, mainMenuPage, pageLayout, translations, styles, preloaderControl, customResolvers);
}

function nonAuthorized(route: Route, pageLayout: PageLayout, translations: Array<Translation> = [], styles: Array<StyleSet>, preloaderControl: boolean = false, customResolvers: ResolveData = {}): Route {
  return anyAuthorized(false, route, MainMenuPage.none, pageLayout, translations, styles, preloaderControl, customResolvers);
}

function anyAuthorized(loggedIn: boolean, route: Route, mainMenuPage: MainMenuPage, pageLayout: PageLayout, translations: Array<Translation> = [], styles: Array<StyleSet>, preloaderControl: boolean = false, customResolvers: ResolveData = {}): Route {
  route.resolve = {
    translation: TranslationsResolver,
    mainMenu: MainMenuResolver,
    pageLayout: PageLayoutResolver,
    styles: StylesResolver
  };
  for (const key in customResolvers) {
    route.resolve[key] = customResolvers[key];
  }
  if (route.data == null) {
    route.data = {};
  }
  route.data["styles"] = defaultStyles.concat(styles);
  route.data["translations"] = defaultTranslations.concat(translations); // 'translations' will be read by TranslationsResolver to know which translation files to load
  route.data["pageLayout"] = pageLayout; // 'pageLayout' will be read by PageLayoutResolver to know which page layout to use
  route.data["mainMenuPage"] = mainMenuPage; // 'mainMenuPage' will be read by MainMenuResolver to know which menu entry to highlight
  route.data["preloaderControl"] = preloaderControl;
  route.canActivate = loggedIn ? [LoggedInGuard] : [NonLoggedInGuard];
  return route;
}

const organizationRoutes: Routes = [{
    path: "", component: PageLayoutComponent, children: [any({path: "", redirectTo: "/tasks", pathMatch: "full"}, PageLayout.simple, [], []),
      any({path: "email-confirmation/:activationCode/:userId", component: EmailConfirmationPageComponent}, PageLayout.simple, [Translation.emailConfirm, Translation.login, Translation.loginLayout, Translation.password], []),
      any({path: "about", component: AboutPageComponent}, PageLayout.simple, [Translation.login, Translation.loginLayout], []),
      any({path: "impersonate/:impersonateId", component: ImpersonatePageComponent}, PageLayout.simple, [], []),
      any({path: "recover-password", component: PasswordRecoveryPageComponent}, PageLayout.simple, [Translation.passwordRecovery, Translation.loginLayout], []),
      any({path: "password-reset/:activationCode/:userId", component: PasswordResetPageComponent}, PageLayout.simple, [Translation.passwordReset, Translation.password, Translation.login, Translation.loginLayout], []),
      any({path: "authenticate-user/:token", component: AuthenticateUserPageComponent}, PageLayout.simple, [Translation.password, Translation.login, Translation.loginLayout], []),
      nonAuthorized({path: "login", component: LoginPageComponent}, PageLayout.simple, [Translation.login, Translation.loginLayout], []),
      nonAuthorized({path: "login-entra-id/:entraIdIdentifier", component: LoginEntraIdPageComponent}, PageLayout.simple, [Translation.login, Translation.loginLayout], []),
      authorized({path: "user", loadChildren: () => import("./modules/user.module/user.module").then(m => m.UserModule)}, MainMenuPage.settings, PageLayout.mainMenu, [Translation.user, Translation.password, Translation.calendar], [StyleSet.user]),
      authorized({path: "notifications", loadChildren: () => import("./modules/user.module/user.module").then(m => m.UserModule)}, MainMenuPage.settings, PageLayout.mainMenu, [Translation.user, Translation.password, Translation.calendar], [StyleSet.user]),
      authorized({path: "main-dashboard", loadChildren: () => import("./modules/main-dashboard.module/main-dashboard.module").then(m => m.MainDashboardModule)}, MainMenuPage.dashboard, PageLayout.mainMenu, [Translation.mainDashboard], [StyleSet.mainDashboard]),
      authorized({path: "tasks", loadChildren: () => import("./modules/tasks.module/tasks.module").then(m => m.TasksModule)}, MainMenuPage.tasksAndFlows, PageLayout.mainMenu, [Translation.tasks, Translation.tasksDashboard, Translation.formValidationErrors], [StyleSet.tasks]),
      authorized({path: "flows", loadChildren: () => import("./modules/flows.module/flows.module").then(m => m.FlowsModule)}, MainMenuPage.tasksAndFlows, PageLayout.mainMenu, [Translation.tasks, Translation.flows, Translation.tasksDashboard, Translation.formValidationErrors], [StyleSet.flows, StyleSet.tasks]),
      authorized({path: "flow", loadChildren: () => import("./modules/flows.module/flows.module").then(m => m.FlowsModule)}, MainMenuPage.tasksAndFlows, PageLayout.mainMenu, [Translation.tasks, Translation.flows, Translation.tasksDashboard, Translation.formValidationErrors, Translation.processMapEditor], [StyleSet.flows, StyleSet.tasks]),
      authorized({path: "documents", loadChildren: () => import("./modules/documents.module/documents.module").then(m => m.DocumentsModule)}, MainMenuPage.documents, PageLayout.mainMenu, [Translation.documentsRepository], [StyleSet.documents]),
      authorized({path: "application", loadChildren: () => import("./modules/application.module/application.module").then(m => m.ApplicationModule)}, MainMenuPage.applications, PageLayout.mainMenu, [Translation.applications, Translation.screen, Translation.reports, Translation.mailbox], [StyleSet.application, StyleSet.screenCommon, StyleSet.screen, StyleSet.report, StyleSet.mailbox, StyleSet.documentation, StyleSet.dataTable, StyleSet.dashboard], false, {application: ApplicationResolverService}),
      authorized({path: "applications", loadChildren: () => import("./modules/applications.module/applications.module").then(m => m.ApplicationsModule)}, MainMenuPage.applications, PageLayout.mainMenu, [Translation.applications], [StyleSet.applications]),
      authorized({path: "designer", loadChildren: () => import("./modules/designer.module/designer.module").then(m => m.DesignerModule)}, MainMenuPage.designer, PageLayout.mainMenu, [Translation.designer, Translation.applications, Translation.processes, Translation.screenEditor, Translation.processMapEditor, Translation.processValidationErrors, Translation.screen, Translation.fileUploader, Translation.functions, Translation.constants, Translation.calendar, Translation.datastores, Translation.schedules, Translation.businessEntities, Translation.businessModel, Translation.mailbox, Translation.mailboxEditor, Translation.databaseConnections, Translation.reports, Translation.dashboardEditor, Translation.dataVisualizationEditor], [StyleSet.designer, StyleSet.screenCommon, StyleSet.dataTable, StyleSet.mailbox, StyleSet.report]),
      authorized({path: "admin", loadChildren: () => import("./modules/admin.module/admin.module").then(m => m.AdminModule)}, MainMenuPage.admin, PageLayout.mainMenu, [Translation.organization, Translation.admin, Translation.groups, Translation.authorization, Translation.system, Translation.calendar, Translation.integrations, Translation.standardCategories], [StyleSet.admin]),
      authorized({path: "help", loadChildren: () => import("./modules/help.module/help.module").then(m => m.HelpModule)}, MainMenuPage.help, PageLayout.mainMenu, [Translation.help], [StyleSet.help]),
      authorized({path: "styles", component: StylesPageComponent}, MainMenuPage.none, PageLayout.simple, [], []),
      authorized({path: "fonts-test", component: FontsTestPageComponent}, MainMenuPage.none, PageLayout.mainMenu, [], []),
      any({path: "**", component: PageNotFoundPageComponent}, PageLayout.simple, [], []),
    ]
  }
];


const platformRoutes: Routes = [
  {
    path: "", component: PageLayoutComponent, children: [
      any({path: "", redirectTo: "/register", pathMatch: "full"}, PageLayout.simple, [], []),
      any({
        path: "register",
        component: RegisterOrganizationPageComponent
      }, PageLayout.simple, [Translation.registerOrganization, Translation.termsAndPolicy, Translation.password], []),
      any({path: "**", component: PageNotFoundPageComponent}, PageLayout.simple, [], []),
    ]
  }
];

const standaloneRoutes: Routes = [
  {path: "screen-widget", children: [
    standalone({ path:'**', loadChildren: () => import("./modules/screen.module/screen.module").then(m => m.ScreenModule), component: ScreenPortletComponent}, [Translation.screen], [StyleSet.sharedModel, StyleSet.screen, StyleSet.screenCommon]),
  ]},
  {path: "process-start-widget", children: [
    standalone({ path:'**', loadChildren: () => import("./modules/task-form.module/task-form.module").then(m => m.TaskFormModule), component: StartTaskPortletComponent}, [Translation.tasksDashboard, Translation.screen, Translation.formValidationErrors], [StyleSet.sharedModel, StyleSet.screen, StyleSet.screenCommon]),
  ]}
]

export function routesFactory(): Routes {
  if(isStandalonePage()) {
    return standaloneRoutes;
  } else if (global.config.organizationId.isDefined()) {
    return organizationRoutes;
  } else {
    return platformRoutes;
  }

}

@Injectable({
  providedIn: "root"
})
export class NoReuseRouteReuseStrategy extends BaseRouteReuseStrategy {
  override shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    // used to prevent angular from reusing route component when url changes.
    // @ is checked to see if it is just popup or modal riggered url changed
    return future.routeConfig === curr.routeConfig && (future.data['reuse'] !== false || valueOrDefault(future.fragment, "").startsWith("@") || valueOrDefault(curr.fragment, "").startsWith("@"));
  }
}


@NgModule({
  imports: [RouterModule.forRoot([])],
  exports: [RouterModule],
  providers: [
    {
      provide: ROUTES,
      multi: true,
      deps: [],
      useFactory: routesFactory,
    },
    {
      provide: RouteReuseStrategy,
      useClass: NoReuseRouteReuseStrategy
    }
  ]
})
export class AppRoutingModule {
  constructor(private readonly router: Router,
              private readonly route: ActivatedRoute,
              private readonly pageLayoutService: PageLayoutService) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.pageLayoutService.applyPageLayout(); // this will apply page layout set by resolver
      }
    });
  }

}


